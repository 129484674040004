@font-face {
    font-family: Gamefont;
    src: url('../../assets/fonts/game-font.ttf');
    font-weight: 400;
    font-weight: normal;
}

@font-face {
    font-family: Textfont;
    src: url('../../assets/fonts/text-font.ttf');
    font-weight: 400;
    font-weight: normal;
}


html, body, #teethris {
    height: 100%;
}

body {
    margin: 0;
    font-family: Gamefont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: #000000;
    color: #ffffff;
}

a {
    color: #008ffd;
    text-decoration: none;
}

h1 {
    font-size: 4rem;
    text-align: center;
}

#teethris {
    cursor: url('../../assets/images/ui/cursor.png') 16 16, auto;
}

a {
    cursor: url('../../assets/images/ui/cursor.png') 16 16, pointer !important;
}
